const options = [
  'Pre-K',
  'K',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12'
] as const;
type GradeOption = typeof options[number];

export function displayGradeLevels(grades: GradeOption[]) {
  if (!grades || !grades.length) return '';

  if (grades.length === 1) {
    return grades[0];
  }

  const gradeValues = grades
    .map(grade => {
      if (grade === 'Pre-K') {
        return -1;
      } else if (grade === 'K') {
        return 0;
      } else {
        return parseInt(grade, 10);
      }
    })
    .sort((a, b) => a - b);

  let result = '';
  let start = gradeValues[0];
  let end = gradeValues[0];

  for (let i = 1; i < gradeValues.length; i++) {
    if (gradeValues[i] === end + 1) {
      end = gradeValues[i]; // Extend the current range
    } else {
      result +=
        getGradeDisplay(start) +
        (end - start > 0 ? '-' + getGradeDisplay(end) : '');
      result += ', ';
      start = gradeValues[i];
      end = gradeValues[i];
    }
  }

  result +=
    getGradeDisplay(start) +
    (end - start > 0 ? '-' + getGradeDisplay(end) : ''); // Add the last range

  return result;
}

function getGradeDisplay(value: number) {
  if (value === -1) {
    return 'Pre-K';
  } else if (value === 0) {
    return 'K';
  } else {
    return value.toString();
  }
}

export const transformGradeString = (gradeString?: string) => {
  if (!gradeString) return [];
  const gradeRanges = gradeString.split(',');
  let grades: any[] = [];

  for (let i = 0; i < gradeRanges.length; i++) {
    const range = gradeRanges[i].trim();
    const rangeGrades: any[] = range.split('-');
    const startGradeIndex = options.indexOf(rangeGrades[0]);
    const endGradeIndex = options.indexOf(rangeGrades[1]);
    const rangeGradesArray = options.slice(startGradeIndex, endGradeIndex + 1);

    grades = grades.concat(rangeGradesArray);
  }

  return grades;
};
